@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: theme('colors.slate.900');

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  scroll-behavior: smooth
}

body,
#root {
  min-height: 100vh;
  margin: 0;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  overflow-x: hidden;
}

#root:before,
#root:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: '';
  filter: blur(150px);
  transform: translateZ(0);
  margin: auto;
}

#root:before {
  opacity: .15;
  background: theme('colors.green.600');
  border-radius: 50%;
  width: 80vw;
  height: 60vh;
  top: -60vh;
  transform: translateX(30vh);
}

#root:after {
  opacity: .2;
  background: theme('colors.blue.500');
  border-radius: 50%;
  width: 60vw;
  height: 80vh;
  top: -20vh;
  transform: translateX(-30vh);
}

a {
  font-weight: 500;
  color: theme('colors.blue.500');
  text-decoration: inherit;
}

a:hover {
  color: theme('colors.blue.100');
}

.logo {
  width: 180px;
  height: auto;
}

.home__cars-wrapper {
  @apply grid 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-3 grid-cols-2 w-full gap-8 pt-8;
}

.car-card__content-title {
  @apply text-[14px] leading-[26px] capitalize;
}

.dropdown {
  @apply absolute right-[50px] top-[-30px] mt-3 w-full p-5 rounded-lg bg-white/50 min-w-[210px] flex flex-col gap-2 justify-end items-end;
}

.dropdown_link {
  @apply text-sm text-gray-700 hover:text-gray-500 font-medium;
}

@media (prefers-color-scheme: light) {
  :root {
    color: theme('colors.slate.800');
    background-color: theme('colors.white');
  }
  a:hover {
    color: theme('colors.blue.400');
  }
  #root:before {
    opacity: .05;
    background: theme('colors.yellow.500');
  }
  #root:after {
    opacity: .08;
  }
}
